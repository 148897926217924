import React, {Component} from "react";
import {RouteComponentProps} from "react-router-dom";
import isAuthenticated from "../util/auth";
import {routes} from "../Router";
import {Page} from "../components/Page";
import {ReactComponent as ByteoutLogo} from "../assets/images/byteout-logo.svg";

export default class Onboarding extends Component<RouteComponentProps> {

  componentDidMount(): void {
    if (!isAuthenticated()) {
      this.props.history.push(routes.login.path);
    }
  }

  render() {
    return (
      <div>
        <Page>
          <Page.Content>
            <ByteoutLogo />
            <h1 className={ 'page__title' }>Tech Gatara</h1>
            <ul className={'list'}>
              <li className={'list__item'}>
                <b>Odgovori Tech Gatari</b> na nekoliko pitanja i saznaj svoju programersku budućnost.
              </li>
              <li className={'list__item'}>
                <b>Gatara ti daje 3 života</b>, što znači da možeš da pogrešiš samo 3 puta u toku kviza.
              </li>
              <li className={'list__item'}>
                Rezultate kviza, kao i rešenje, poslaćemo na tvoj imejl.
              </li>
            </ul>
          </Page.Content>
          <button
            className={"btn"}
            onClick={() => this.props.history.push(routes.quiz.path)}
          >
            Započni igru
          </button>
        </Page>
      </div>
    );
  }
}
