import React, {Component} from "react";
import { ReactComponent as CorrectCheckmark } from '../assets/images/correct-checkmark.svg';
import { ReactComponent as WrongCheckmark } from '../assets/images/wrong-checkmark.svg';
import { IOptionIcons } from "../model/IOptionIcons";
import {RadioButton} from "./RadioButton";
import IAnswer from "../model/IAnswer";

interface AnswerProps {
  answer: IAnswer;
  handleAnswerSelect: (answerId: string) => void;
  isUserSelected: boolean;
}

export class Answer extends Component<AnswerProps> {
  render() {
    const { answer, handleAnswerSelect, isUserSelected } = this.props;
    let className = '';
    let radioButtonIcons: IOptionIcons = {};

    if (answer.isSelected) {
      className = 'answer--is-selected';

      if (answer.isCorrect !== undefined) {
        radioButtonIcons = {
          selected: answer.isCorrect ? <CorrectCheckmark/> : <WrongCheckmark/>,
        };

        className = answer.isCorrect ? 'answer--is-correct' : 'answer--is-error';
      }
    }

    return (
      <div className={`answer ${className}`}>
        <RadioButton
          id={`answer-${answer.id}`}
          text={answer.answer}
          name={'answer'}
          disabled={isUserSelected}
          checked={answer.isSelected || false}
          onChange={handleAnswerSelect}
          value={answer.id.toString()}
          radioButtonIcons={radioButtonIcons}
        />
      </div>
    );
  }
}
