import React, {Component} from "react";

interface progressBarProps {
  width: number;
}

export class ProgressBar extends Component<progressBarProps> {
  render() {
    return (
      <div className={'progress-bar'} >
        <div className={this.props.width === 100 ? 'progress-bar--end' : 'progress-bar--ongoing'} style={{ width: `${this.props.width}%` }} />
      </div>
    )
  };
}
