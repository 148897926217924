import React, {Component} from "react";
import { ReactComponent as MagicBallWorking } from '../assets/images/magic-ball-working.svg';
import { ReactComponent as MagicBallWrongDarkForce } from '../assets/images/magic-ball-wrong-dark-force.svg';
import { ReactComponent as MagicBallWrongFallAngel } from '../assets/images/magic-ball-wrong-fall-angel.svg';
import { ReactComponent as MagicBallWrongEndGame } from '../assets/images/magic-ball-wrong-end-game.svg';
import { ReactComponent as MagicBallCorrect } from '../assets/images/magic-ball-correct.svg';
import { ReactComponent as MagicBallSelected } from '../assets/images/magic-ball-selected.svg';
import { ReactComponent as MagicBallStreak } from '../assets/images/magic-ball-streak.svg';
import { ReactComponent as MagicBallLowResult } from '../assets/images/magic-ball-low-result.svg';
import { ReactComponent as MagicBallNormalResult } from '../assets/images/magic-ball-normal-result.svg';
import { ReactComponent as MagicBallHighResult } from '../assets/images/magic-ball-high-result.svg';

interface MagicBallProps {
  magicBall: any;
  streak?: number;
  points?: number;
}

export class MagicBall extends Component<MagicBallProps> {

  render() {
    const { magicBall, points, streak } = this.props;
    console.log(points);
    console.log(streak);

    return (
      <div className={"magic-ball"}>
        {points !== undefined && points !== 0 &&
          <div className={`magic-ball__points`}>+{points}</div>
        }
        {streak !== undefined && streak !== 1 &&
          <div className={"magic-ball__streak"}>x{streak}</div>
        }
        {magicBall}
      </div>
    );
  }
}
