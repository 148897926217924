import React, {Component} from "react";

export class FormFieldSet extends Component {

  render() {
    return (
      <fieldset className={ "form-element__fieldset" }>
        {this.props.children}
      </fieldset>
    );
  };
}
