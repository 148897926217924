import React, {Component} from "react";
import {InfoBoardText} from "../components/InfoBoardText";
import {RouteComponentProps} from "react-router";

interface matchProps {
  quizId: string,
}

export default class Leaderboard extends Component<RouteComponentProps<matchProps>> {
  render() {
    let infoBoardTextContent =
      {
        goBack:  this.props.history.goBack,
        title: "Rang lista",
      };
    return (
      <div>
        <InfoBoardText {...infoBoardTextContent} />
        <h1>LEADERBOARD</h1>
      </div>
    );
  }
}
