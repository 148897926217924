import IQuizSession from "../model/IQuizSession";

export default function isAuthenticated(): boolean {
  const byteoutQuiz = sessionStorage.getItem('byteout-quiz');

  if (byteoutQuiz) {
    return true;
  }

  return false;
}

export function getQuizIdFromSession(): number|false {
  const byteoutQuizJson = sessionStorage.getItem('byteout-quiz');

  if (byteoutQuizJson) {
    const byteoutQuiz: IQuizSession = JSON.parse(byteoutQuizJson);

    return byteoutQuiz.quizId;
  }

  return false;
}

export function setQuizIdInSession(quizId: number) {
  let byteoutQuiz: IQuizSession = { quizId };
  let byteoutQuizJSON = JSON.stringify(byteoutQuiz);

  sessionStorage.setItem('byteout-quiz', byteoutQuizJSON);
}
