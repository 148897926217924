import React, {Component} from "react";
import {Checkbox} from "./Checkbox";
import ICategoryState from "../model/ICategoryState";

interface categoriesProps {
  onChange: (event: any) => void;
  categories: ICategoryState[]|null;
}

export class Categories extends Component<categoriesProps> {

  render() {
    return(
      <div className={'form-element__group'}>
        {
          this.props.categories && this.props.categories.map(categoryProp => {
            return (
              <Checkbox
                className={ 'layout__checkbox' }
                key={categoryProp.category.id}
                text={categoryProp.category.name}
                id={categoryProp.category.id.toString()}
                checked={categoryProp.checked}
                name={categoryProp.category.id.toString()}
                onChange={this.props.onChange}
              />)
          })
        }
      </div>
    )
  };
}
