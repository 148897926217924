import React, {Component} from "react";
interface TextFieldProps {
  className?: string;
  name: string;
  onChange:(event: any) => void;
  value: string;
  type?: string;
  placeholder: string;
  required: boolean;
}
export class TextField extends Component<TextFieldProps>{
  render() {

    const {
      value,
      name,
      className,
      placeholder,
      onChange,
      type,
      required
    } = this.props;

    return (
      <input
        type={type ? type : "text"}
        value={value}
        placeholder={placeholder}
        className={`form-element form-element__text-field ${className ? className : ""}`}
        name={name}
        onChange={onChange}
        required={required}
      />
    );
  }
}
