import React, {Component} from "react";
import {NavLink, RouteComponentProps} from "react-router-dom";
import {InfoBoardScore} from "../components/InfoBoardScore";
import isAuthenticated, {getQuizIdFromSession} from "../util/auth";
import {routes} from "../Router";
import QuizService from "../services/QuizService";
import IQuiz from "../model/IQuiz";
import {Page} from "../components/Page";
import {MagicBall} from "../components/MagicBall";
import {getEndMagicBall, getMagicBallPredictionText} from "../util/magicBallHelpers";

interface quizEndState {
  quiz: IQuiz|null
}

export default class QuizEnd extends Component<RouteComponentProps, quizEndState> {

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      quiz: null
    };
  };

  componentDidMount(): void {
    const { history } = this.props;

    if (!isAuthenticated()) {
      history.push(routes.login.path);
    }

    const quizId = getQuizIdFromSession();

    if (quizId) {
      QuizService
        .getQuiz(quizId)
        .then((quiz: IQuiz) => {

          if (!quiz.state.isGameOver) {
            history.push(routes.quiz.path);
          }

          this.setState({ quiz })
        })
    }
  }

  getMagicBallPredictionText() {
    const { quiz } = this.state;

    if (!quiz) {
      return null;
    }

  }

  render() {
    const { quiz } = this.state;

    if (!quiz) {
      return null;
    }

    const previousQuestions = quiz.answeredQuestions.length;
    const maxNumberOfQuestions = quiz.config.maxNumberOfQuestions ? quiz.config.maxNumberOfQuestions : 1;
    const currentQuestionNum = previousQuestions + 1;

    let infoBoardScoreContent = {
      numOfLives: quiz.state.lives,
      points: quiz.state.points,
      progressBar: currentQuestionNum ? ((currentQuestionNum / maxNumberOfQuestions) * 100) : 100,
    };

    return(
      <Page>
        <Page.Content>
          <InfoBoardScore {...infoBoardScoreContent} />
          <div className={"quiz quiz--end"}>
            <MagicBall
              magicBall={getEndMagicBall(quiz.state.points)}
            />

            <p>
              Sa osvojenih {quiz.state.points} poena {getMagicBallPredictionText(quiz.state.points)}.
            </p>

            {/*<p><NavLink to={'/'}>Klikni ovde</NavLink> ako želiš da igraš ponovo.</p>*/}

            <p>Upoznaj <NavLink to={routes.company.path}>Byteout Software</NavLink> kulturu.</p>

            <div className={"quiz__actions"}>
              <NavLink className={"btn"} to={routes.result.path}>Rešenje</NavLink>
              <NavLink className={"btn"} to={routes.login.path}>Igraj ponovo</NavLink>
            </div>
          </div>
        </Page.Content>
      </Page>
    );

  }
}
