import IQuiz from "../model/IQuiz";
import IQuestion from "../model/IQuestion";
import ICategory from "../model/ICategory";
import ICreateObject from "../model/ICreateObject";
import IUserAnswerParams from "../model/IUserAnswerParams";
import {globalVariables} from "../util/global";

export default class QuizService {

  static getCategories(): Promise<ICategory[]> {
    return fetch(`${globalVariables.apiUrl}category`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(QuizService.resolveApiResponse)
    .then(categories => categories);
  };

  static createQuiz(createQuizObject: ICreateObject): Promise<IQuiz> {
    console.log("Creating quiz with params: ", createQuizObject);

    return fetch(`${globalVariables.apiUrl}quiz`, {
      body: JSON.stringify(createQuizObject),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(QuizService.resolveApiResponse)
    .then((quiz: IQuiz) => {

      console.log("Created quiz: ", quiz);

      return quiz;
    });

    // return fetch("http://dev.byteout.com/quiz2019/public/api/v0/quiz")
    //   .then(response => response.json())
    //   .then(response => {
    //     let quizInit : IQuiz = {
    //       id: response.id,
    //       questionCount: response.questionCount,
    //       player: {id: 15, email: "marko.markovic@gmail.com"},
    //       categories: [{id: 0, name: "Category 1"}],
    //       state: {
    //         lives: 3,
    //         points: 0,
    //         streak: 0,
    //         multiplicationFactor: 0,
    //       },
    //       answeredQuestions: []
    //     };
    //     return quizInit;
    //   });
  };

  static getQuiz(quizId: number): Promise<IQuiz> {
    return fetch(`${globalVariables.apiUrl}quiz/${quizId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
     })
    .then(QuizService.resolveApiResponse)
    .then((quiz: IQuiz) => {

      console.log("Get quiz: ", quiz);
      return quiz;
    });

    // let quizInit : IQuiz = {
    //   id: 1,
    //   questionCount: 10,
    //   player: {id: 15, email: "marko.markovic@gmail.com"},
    //   categories: [{id: 0, name: "Category 1"}],
    //   state: {
    //     lives: 3,
    //     points: 1234,
    //     streak: 3,
    //     multiplicationFactor: 2,
    //   },
    //   answeredQuestions: [
    //     {id: 0, level: 0, question: "Question0",
    //       answers: [
    //         {id: 0, answer:"Answer0", isSelected: true, isCorrect:true},
    //         {id: 1, answer:"Answer1", isSelected: false, isCorrect:false},
    //         {id: 2, answer:"Answer2", isSelected: false, isCorrect:false},
    //         {id: 3, answer:"Answer3", isSelected: false, isCorrect:false}]
    //     },
    //     {id: 1, level: 0, question: "Question1",
    //       answers: [
    //         {id: 0, answer:"Answer0", isSelected: true, isCorrect:true},
    //         {id: 1, answer:"Answer1", isSelected: false, isCorrect:false},
    //         {id: 2, answer:"Answer2", isSelected: false, isCorrect:false},
    //         {id: 3, answer:"Answer3", isSelected: false, isCorrect:false}]
    //     },
    //     {id: 2, level: 0, question: "Question0",
    //       answers: [
    //         {id: 0, answer:"Answer0", isSelected: true, isCorrect:true},
    //         {id: 1, answer:"Answer1", isSelected: false, isCorrect:false},
    //         {id: 2, answer:"Answer2", isSelected: false, isCorrect:false},
    //         {id: 3, answer:"Answer3", isSelected: false, isCorrect:false}]
    //     },
    //     {id: 3, level: 0, question: "Question1",
    //       answers: [
    //         {id: 0, answer:"Answer0", isSelected: true, isCorrect:true},
    //         {id: 1, answer:"Answer1", isSelected: false, isCorrect:false},
    //         {id: 2, answer:"Answer2", isSelected: false, isCorrect:false},
    //         {id: 3, answer:"Answer3", isSelected: false, isCorrect:false}]
    //     },
    //     {id: 4, level: 0, question: "Question0",
    //       answers: [
    //         {id: 0, answer:"Answer0", isSelected: true, isCorrect:true},
    //         {id: 1, answer:"Answer1", isSelected: false, isCorrect:false},
    //         {id: 2, answer:"Answer2", isSelected: false, isCorrect:false},
    //         {id: 3, answer:"Answer3", isSelected: false, isCorrect:false}]
    //     },
    //     {id: 5, level: 0, question: "Question1",
    //       answers: [
    //         {id: 0, answer:"Answer0", isSelected: true, isCorrect:true},
    //         {id: 1, answer:"Answer1", isSelected: false, isCorrect:false},
    //         {id: 2, answer:"Answer2", isSelected: false, isCorrect:false},
    //         {id: 3, answer:"Answer3", isSelected: false, isCorrect:false}]
    //     },
    //     {id: 6, level: 0, question: "Question0",
    //       answers: [
    //         {id: 0, answer:"Answer0", isSelected: true, isCorrect:true},
    //         {id: 1, answer:"Answer1", isSelected: false, isCorrect:false},
    //         {id: 2, answer:"Answer2", isSelected: false, isCorrect:false},
    //         {id: 3, answer:"Answer3", isSelected: false, isCorrect:false}]
    //     },
    //     {id: 7, level: 0, question: "Question1",
    //       answers: [
    //         {id: 0, answer:"Answer0", isSelected: true, isCorrect:true},
    //         {id: 1, answer:"Answer1", isSelected: false, isCorrect:false},
    //         {id: 2, answer:"Answer2", isSelected: false, isCorrect:false},
    //         {id: 3, answer:"Answer3", isSelected: false, isCorrect:false}]
    //     },
    //     {id: 8, level: 0, question: "Question0",
    //       answers: [
    //         {id: 0, answer:"Answer0", isSelected: true, isCorrect:true},
    //         {id: 1, answer:"Answer1", isSelected: false, isCorrect:false},
    //         {id: 2, answer:"Answer2", isSelected: false, isCorrect:false},
    //         {id: 3, answer:"Answer3", isSelected: false, isCorrect:false}]
    //     },
    //     {id: 9, level: 0, question: "Question1",
    //       answers: [
    //         {id: 0, answer:"Answer0", isSelected: true, isCorrect:false},
    //         {id: 1, answer:"Answer1", isSelected: false, isCorrect:true},
    //         {id: 2, answer:"Answer2", isSelected: false, isCorrect:false},
    //         {id: 3, answer:"Answer3", isSelected: false, isCorrect:false}]
    //     },
    //   ]
    // };

    // return Promise.resolve('');
  };

  static getQuestion(quizId: number): Promise<IQuestion> {
    return fetch(`${globalVariables.apiUrl}quiz/${quizId}/question`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(QuizService.resolveApiResponse)
    .then((question: IQuestion) => {
      console.log("Get question: ", question);
      return question;
    });

    // let questionResponse : IQuestion = {
    //   id: 123,
    //   question: "Question Sample",
    //   level: 0,
    //   answers: [
    //     { id: 0, answer: "Answer sample 0" },
    //     { id: 1, answer: "Answer sample 1" },
    //     { id: 2, answer: "Answer sample 2" },
    //     { id: 3, answer: "Answer sample 3" }
    //   ]
    // };

    // return Promise.resolve(questionResponse);
  };

  static sendUserAnswer(quizId: number, userAnswerParams : IUserAnswerParams): Promise<IQuestion> {
    return fetch(`${globalVariables.apiUrl}quiz/${quizId}/question`, {
      method: 'POST',
      body: JSON.stringify(userAnswerParams),
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(response => response.json())
    .then((question: IQuestion) => {
      console.log("Send user answer response: ", question);

      return question;
    });

    // const answerId = userAnswerParams.answers[0].id;
    //
    // let questionResponse : IQuestion = {
    //   id: 123,
    //   question: "Question Sample Number 2",
    //   level: 0,
    //   answers: [
    //     { id: 0, answer: "Answer sample 0", isCorrect: true, isSelected: answerId === 0 },
    //     { id: 1, answer: "Answer sample 1", isCorrect: false, isSelected: answerId === 1 },
    //     { id: 2, answer: "Answer sample 2", isCorrect: false, isSelected: answerId === 2 },
    //     { id: 3, answer: "Answer sample 3", isCorrect: false, isSelected: answerId === 3 }
    //   ]
    // };
    // return Promise.resolve(questionResponse);
  };

  static resolveApiResponse(response: Response) {
    if (response.ok) {
      return Promise.resolve(response.json());
    }

    return Promise.reject(response.json());
  }
}
