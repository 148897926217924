import React, { ChangeEvent, Component } from "react";
import { ReactComponent as RadioUnchecked } from '../assets/images/radio-unchecked.svg';
import { ReactComponent as RadioChecked } from '../assets/images/radio-checked.svg';
import { IOptionIcons } from "../model/IOptionIcons";

interface RadioButtonProps {
  text: string;
  className?: string;
  name: string;
  onChange: (...args: any[]) => void;
  disabled: boolean;
  radioButtonIcons?: IOptionIcons;
  checked: boolean;
  id: string;
  value: string;
}

export class RadioButton extends Component<RadioButtonProps> {

  private readonly defaultIcons: IOptionIcons = {
    selected: <RadioChecked/>,
    unselected: <RadioUnchecked/>,
  };

  onChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    this.props.onChange(value);
  }

  render() {
    const {
      radioButtonIcons = {},
      checked,
      name,
      className,
      text,
      disabled,
      id,
      value
    } = this.props;

    if (!radioButtonIcons.selected) {
      radioButtonIcons.selected = this.defaultIcons.selected;
    }

    if (!radioButtonIcons.unselected) {
      radioButtonIcons.unselected = this.defaultIcons.unselected
    }

    return (
      <div className={`form-element form-element__radio ${className ? className : ""}`}>
        <input
          type="radio"
          id={id}
          checked={checked}
          name={name}
          disabled={disabled}
          onChange={this.onChange.bind(this)}
          value={value}
        />
        <label htmlFor={id} >
          {checked ? radioButtonIcons.selected : radioButtonIcons.unselected}
          {text}
        </label>
      </div>
    );
  }
}
