import React, {Component} from "react";
import {PageContent} from "./PageContent";

interface PageProps {
  className?: string;
}

export class Page extends Component<PageProps, any> {
  static Content = PageContent;

  render() {
    return (
      <div className={`page ${this.props.className ? this.props.className : ''}`}>
        {this.props.children}
      </div>
    );
  }
}
