import React, {Component} from "react";

export class InfoBoard extends Component {

  render() {
    return (
      <section className={'board'}>
          {this.props.children}
      </section>
    );

  };
}
