import React, {Component} from "react";

export class PageContent extends Component<any, any> {
  render() {
    return (
      <div className={'page__content'}>
        {this.props.children}
      </div>
    );
  }
}
