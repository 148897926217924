import React, {Component} from "react";

export class FormFieldSetLegend extends Component {

  render() {
    return (
      <legend className={ "form-element__fieldset-legend" }>{this.props.children}</legend>
    );
  };
}
