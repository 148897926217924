import React, { Component } from "react";
import { InfoBoard } from './InfoBoard';
import { Lives } from "./Lives";
import { ReactComponent as ByteoutLogo } from './../assets/images/byteout-logo.svg';
import { ProgressBar } from "./ProgressBar";

interface InfoBoardScoreProps {
  points: number;
  progressBar: number;
  numOfLives: number;
}

export class InfoBoardScore extends Component<InfoBoardScoreProps> {
  render() {
    return(
      <div>
        <InfoBoard>
          <section className={'board__content-score'}>
            <div className={'board__content-score-left'}>
                <Lives lifeCount={this.props.numOfLives} />
            </div>
            <div className={'board__content-score-center'}>
                <ByteoutLogo />
            </div>
            <div className={'board__content-score-right'}>
              <div className={'board__points'}>
                {this.props.points}
              </div>
            </div>
          </section>
        </InfoBoard>
        <ProgressBar width={this.props.progressBar} />
      </div>
    );
  };
}
