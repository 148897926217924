import React, {Component} from "react";
import {NavLink, RouteComponentProps} from "react-router-dom";
import QuizService from "../services/QuizService";
import {InfoBoardText} from "../components/InfoBoardText";
import QuestionResult from "../components/QuestionResult";
import IQuiz from "../model/IQuiz";
import isAuthenticated, {getQuizIdFromSession} from "../util/auth";
import {routes} from "../Router";
import {Page} from "../components/Page";

interface resultState {
  quiz: IQuiz|null;
}

export default class Result extends Component<RouteComponentProps, resultState> {

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      quiz: null,
    };
  }

  componentDidMount(): void {
    if (!isAuthenticated()) {
      this.props.history.push(routes.login.path);
    }

    this.getResult();
  };

  getResult(): void {
    const { history } = this.props;

    const quizId = getQuizIdFromSession();

    if (quizId) {
      QuizService
        .getQuiz(quizId)
        .then((quiz: IQuiz) => {

          if (!quiz.state.isGameOver) {
            history.push(routes.quiz.path);
          }

          this.setState({ quiz })
        })
    }
  };

  render() {
    if (!this.state.quiz) {
      return null;
    }

    let infoBoardTextContent =  {
      goBack:  this.props.history.goBack,
      title: "Rešenje",
      subtitle: this.state.quiz ? `${this.state.quiz.state.points}` : `${0}`,
    };

    let questions = this.state.quiz ? this.state.quiz.answeredQuestions : null;

    if (questions) {
      return (
        <Page className={'page--with-board'}>
          <Page.Content>
            <InfoBoardText {...infoBoardTextContent} />
            {
              questions.map(question =>
                <QuestionResult key={question.id} question={question}/>)
            }

            <div className={"quiz__actions"}>
              <NavLink className={"btn"} to={routes.login.path}>Igraj ponovo</NavLink>
            </div>
          </Page.Content>
        </Page>
      );
    }
  }
}
