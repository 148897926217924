import React, {Component} from "react";
import IQuiz from '../model/IQuiz';
import QuizService from "../services/QuizService";
import {Question} from "../components/Question";
import {InfoBoardScore} from "../components/InfoBoardScore";
import IQuestion from "../model/IQuestion";
import {NavLink, RouteComponentProps} from "react-router-dom";
import isAuthenticated, {getQuizIdFromSession} from "../util/auth";
import IUserAnswerParams from "../model/IUserAnswerParams";
import {routes} from "../Router";
import {Page} from "../components/Page";
import {MagicBall} from "../components/MagicBall";
import IAnswer from "../model/IAnswer";
import IError from "../model/IError";
import {ReactComponent as MagicBallWorking} from "../assets/images/magic-ball-working.svg";
import { ReactComponent as MagicBallSelected } from '../assets/images/magic-ball-selected.svg';
import { ReactComponent as MagicBallCorrect } from '../assets/images/magic-ball-correct.svg';
import { ReactComponent as MagicBallStreak } from '../assets/images/magic-ball-streak.svg';
import { ReactComponent as MagicBallByteout } from '../assets/images/magic-ball-byteout.svg';
import {getWrongMagicBall} from "../util/magicBallHelpers";

interface QuizState {
  quiz: IQuiz|null;
  startQuiz: boolean;
  question: IQuestion|null;
  userAnswered: number;
  currentPoints: number;
  currentLives: number;
  totalPoints: number;
}

export default class Quiz extends Component<RouteComponentProps, QuizState> {

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      quiz: null,
      startQuiz: false,
      question: null,
      userAnswered: 0,
      currentPoints: 0,
      currentLives: 0,
      totalPoints: 0,
    };
    this.getNextQuestion = this.getNextQuestion.bind(this);
    this.sendUserAnswer = this.sendUserAnswer.bind(this);
  };

  componentDidMount(): void {
    if (!isAuthenticated()) {
      this.props.history.push(routes.login.path);
    }

    const quizId = getQuizIdFromSession();

    if (quizId) {
      this.initializeQuiz(quizId);
    }
  };

  initializeQuiz(quizId : number): void {
    this.updateQuiz(quizId)
      .then((quiz: IQuiz) => {
        console.log(quiz);
        this.moveForward(quiz);
      })
  }

  updateQuiz(quizId: number) {
    return this.getQuiz(quizId);
  }

  getQuiz(quizId: number) {
    return QuizService
      .getQuiz(quizId)
      .then((quiz: IQuiz) => {
        this.setState({ quiz });

        return quiz;
      });
  }

  moveForward(quiz: IQuiz): void {
    if (quiz.state.isGameOver) {
      setTimeout(() => this.props.history.push(routes.quizEnd.path), 400);
    } else {
      this.getNextQuestion();
    }
  }

  getNextQuestion(): void {
    if (!this.state.quiz) {
      return;
    }

    const quizId = this.state.quiz.id;
    const totalPoints = this.state.quiz.state.points;
    const currentLives = this.state.quiz.state.lives;

    setTimeout(() => {
      QuizService
        .getQuestion(quizId)
        .then((question: IQuestion) => {
          console.log('set question');

          this.setState({
            question,
            currentLives,
            currentPoints: totalPoints,
            userAnswered: 0
          });
        })
        .catch((error : IError) => {
          console.log(error);
        });
    }, 400);

    // todo: remove setTimeout when api is available
    // QuizService
    //   .getQuestion(this.state.quiz.id)
    //   .then((question: IQuestion) => {
    //     console.log('set question');
    //
    //     setTimeout(() => this.setState({question, userAnswered: 0}), 400);
    //   })
    //   .catch((error : IError) => {
    //     console.log(error);
    //   });
  };

  sendUserAnswer(answerId: number): void {
    const quizId = this.state.quiz ? this.state.quiz.id : 0;
    const questionId = this.state.question ? this.state.question.id : 0;

    if (!quizId && !questionId) {
      return;
    }

    this.setState({ userAnswered: answerId });

    const userAnswerParams: IUserAnswerParams = {
      id: questionId,
      answers: [{
        id: answerId,
        isSelected: true
      }]
    };

    // todo: remove setTimeout when api is available
    QuizService
      .sendUserAnswer(quizId, userAnswerParams)
      .then((question: IQuestion) => setTimeout(() => this.setState({ question }), 400))
      .then(() => this.updateQuiz(quizId))
      .then((quiz: IQuiz) => setTimeout(() => this.moveForward(quiz), 800));
  };

  render() {
    const { quiz, question, userAnswered, currentPoints, currentLives } = this.state;

    if (quiz && question) {

      const previousQuestions = quiz.answeredQuestions.length;
      const maxNumberOfQuestions = quiz.config.maxNumberOfQuestions ? quiz.config.maxNumberOfQuestions : 1;
      const numberOfLives = currentLives;
      const currentQuestionNum = previousQuestions + 1;

      let infoBoardScoreContent = {
        numOfLives: numberOfLives,
        points: currentPoints,
        progressBar: currentQuestionNum ? ((currentQuestionNum / maxNumberOfQuestions) * 100) : 100,
      };

      let questionContent =
      {
        question: question,
        points: quiz.state.points,
        sendUserAnswer: this.sendUserAnswer,
        userAnswered: userAnswered
      };


      const userAnswerObject = question.answers.find((answer: IAnswer) => answer.id === userAnswered);

      let questionPoints = 0;

      let magicBall =  <MagicBallWorking/>;

      if (quiz.state.isBonus) {
        magicBall = <MagicBallStreak/>;
      }

      if (userAnswered) {
        magicBall = <MagicBallSelected />;
      }

      if (userAnswerObject && userAnswerObject.isCorrect !== undefined) {
        if (userAnswerObject.isCorrect) {
          magicBall = <MagicBallCorrect/>;
          questionPoints = quiz.state.points - currentPoints;
        } else {
          magicBall = getWrongMagicBall(quiz.state.lives);
        }
      }

      if (question.category.name === "Byteout" && !userAnswered) {
        magicBall = <MagicBallByteout/>;
      }

      return (
        <Page>
          <Page.Content>
            <InfoBoardScore {...infoBoardScoreContent} />
            <div className={"quiz"}>
              <MagicBall
                magicBall={magicBall}
                streak={quiz.state.isBonus && !userAnswered ? quiz.state.bonusMultiplier : 1}
                points={questionPoints}
              />
              <Question {...questionContent}/>
              <NavLink to={routes.login.path} className={"quiz__quit"}>Odustani od igre</NavLink>
            </div>
          </Page.Content>
        </Page>
      );
    }

    return null;
  }
}
