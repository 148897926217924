import React, { Component } from "react";
import { ReactComponent as CheckboxUnchecked } from '../assets/images/checkbox-unchecked.svg';
import { ReactComponent as CheckboxChecked } from '../assets/images/checkbox-checked.svg';
import { IOptionIcons } from "../model/IOptionIcons";

interface CheckboxProps {
  text: string;
  className?: string;
  name: string;
  onChange:(event:any) => void;
  checked: boolean;
  checkboxIcons?: IOptionIcons;
  id: string;
}
export class Checkbox extends Component<CheckboxProps>{
  private readonly defaultIcons: IOptionIcons = {
    selected: <CheckboxChecked/>,
    unselected: <CheckboxUnchecked/>,
  };

  render() {
    const {
      checkboxIcons = this.defaultIcons,
      id,
      text,
      checked,
      className,
      name,
      onChange
    } = this.props;

    if (!checkboxIcons.selected) {
      checkboxIcons.selected = this.defaultIcons.selected;
    }

    if (!checkboxIcons.unselected) {
      checkboxIcons.unselected = this.defaultIcons.unselected
    }

    return (
      <div className={`form-element form-element__checkbox ${className ? className : ""}`}>
        <input
          type="checkbox"
          name={name}
          id={id}
          checked={checked}
          onChange={onChange}
        />
        <label htmlFor={id}>{checked ? checkboxIcons.selected : checkboxIcons.unselected}{text}</label>
      </div>
    );
  }
}
