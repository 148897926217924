import React, {Component} from "react";
import { ReactComponent as Full } from '../assets/images/heart-full.svg';
import { ReactComponent as Empty } from '../assets/images/heart-empty.svg';

interface LivesProps {
  lifeCount: number
}

export class Lives extends Component<LivesProps> {

  render() {
    let lives = [];
    for (let i = 0; i < 3; i++) {
      if(i < this.props.lifeCount) {
        lives.push(<Full key={i} />);
      }else {
        lives.push(<Empty key={i} />);
      }
    }
    return (<div className={"board__lives"}>{lives}</div>);
  };
}
