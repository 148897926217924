import React, {Component} from "react";
import { RouteComponentProps } from "react-router-dom";
import {TextField} from "../components/TextField";
import {Categories} from "../components/Categories";
import QuizService from "../services/QuizService";
import ICategoryState from "../model/ICategoryState";
import ICreateObject from "../model/ICreateObject";
import isAuthenticated, {getQuizIdFromSession, setQuizIdInSession} from "../util/auth";
import IQuiz from "../model/IQuiz";
import {routes} from "../Router";
import {Page} from "../components/Page";
import {FormFieldSet} from "../components/FormFiledSet";
import {FormFieldSetLegend} from "../components/FormFieldSetLegend";
import {ReactComponent as ByteoutLogo} from "../assets/images/byteout-logo.svg";
import ICategory from "../model/ICategory";

interface loginState {
  quiz: IQuiz|null
  mail: string|null;
  categories: ICategoryState[]|[];
}

export default class Login extends Component<RouteComponentProps, loginState> {

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      quiz: null,
      mail: null,
      categories: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(): void {
    if (isAuthenticated()) {
      const quizId = getQuizIdFromSession();

      if (quizId) {
        QuizService.getQuiz(quizId)
          .then((quiz: IQuiz) => this.setState({ quiz, mail: quiz.player.email }))
          .then(() => this.getCategories());
      }
    } else {
      this.getCategories();
    }
  };

  isDisabledButton() {
    if (!this.state.mail) {
      return true;
    }

    const checkedCategory = this.state.categories.find((category) => category.checked);

    return !checkedCategory;
  }

  getCategories() {
    QuizService
      .getCategories()
      .then((categories: ICategory[]) => {
        const categoriesState : ICategoryState[] = [];
        const playerCategories = this.state.quiz ? this.state.quiz.config.categories : [];

        categories.forEach(category => {
          const playerCategory = playerCategories.find((playerCategory) => playerCategory.id === category.id);

          const categoryAndChecked = {
            category: category,
            checked: !!playerCategory
          };
          categoriesState.push(categoryAndChecked);
        });

        return categoriesState;
      })
      .then((categoriesState: ICategoryState[]) => {
        this.setState({
          categories: categoriesState
        });
      })
  }

  handleChange(event: any) {
    const target = event.target;
    const id = target.id;

    if (target.type === "email") {
      this.setState({ mail: target.value });

    } else {
      if (this.state.categories) {

        const categories = this.state.categories;

        for (let i = 0; i <  this.state.categories.length; i++) {
          let category = this.state.categories[i];
          if (category.category.id.toString() === id) {
            category.checked = !category.checked;
          }
          categories[i] = category;
        }

        this.setState({ categories: categories });
      }
    }
  }

  handleSubmit(event: any) {
    event.preventDefault();

    if (this.state.categories && this.state.mail) {
      let chosenCategories : { id: number; }[] = [];
      let categories : ICategoryState[] = this.state.categories;

      categories.forEach(category  => {
        if (category.checked) {
          chosenCategories.push({
            id: category.category.id
          });
        }
      });

      let createQuizObject : ICreateObject = {
        player: {
          email: this.state.mail
        },
        config: {
          categories: chosenCategories
        }
      };

      const history = this.props.history;

      const redirectPath = isAuthenticated() ? routes.quiz.path : routes.onboarding.path;

      QuizService
        .createQuiz(createQuizObject)
        .then((quiz: IQuiz) => setQuizIdInSession(quiz.id))
        .then(() => history.push(redirectPath));
    }
  }

  render() {
    return (
      <Page>
        <Page.Content>
          <ByteoutLogo />
          <h1 className={ 'page__title' }>Tech Gatara</h1>
          <form onSubmit={this.handleSubmit} className={'form'}>
            <div className={'form__content'}>
              <FormFieldSet>
                <FormFieldSetLegend>Imejl-adresa</FormFieldSetLegend>
                <TextField
                  placeholder={'primer@gmail.com'}
                  value={this.state.mail ? this.state.mail : ''}
                  name={"mail"}
                  onChange={this.handleChange}
                  type={"email"}
                  required={true}
                />
              </FormFieldSet>

              <FormFieldSet>
                <FormFieldSetLegend>Izaberi oblast/i</FormFieldSetLegend>
                <Categories
                  categories={this.state.categories}
                  onChange={this.handleChange}
                />
              </FormFieldSet>
            </div>

            <input
              disabled={this.isDisabledButton()}
              className={"btn form__button"}
              type="submit"
              value={isAuthenticated() ? 'Igraj ponovo' : 'Idemo dalje'}
            />
          </form>
        </Page.Content>
      </Page>
    );
  }
}
