import React from "react";
import {ReactComponent as MagicBallHighResult} from "../assets/images/magic-ball-high-result.svg";
import {ReactComponent as MagicBallNormalResult} from "../assets/images/magic-ball-normal-result.svg";
import {ReactComponent as MagicBallLowResult} from "../assets/images/magic-ball-low-result.svg";
import {ReactComponentElement} from "react";
import {ReactComponent as MagicBallWrongEndGame} from "../assets/images/magic-ball-wrong-end-game.svg";
import {ReactComponent as MagicBallWrongFallAngel} from "../assets/images/magic-ball-wrong-fall-angel.svg";
import {ReactComponent as MagicBallWrongDarkForce} from "../assets/images/magic-ball-wrong-dark-force.svg";

export function getEndMagicBall(points: number): ReactComponentElement<any, any> {
  if (points > 1500) {
    return <MagicBallHighResult/>;
  }

  if (points > 1000) {
    return <MagicBallNormalResult/>;
  }

  return <MagicBallLowResult/>;
}

export function getWrongMagicBall(lives: number) {
  if (lives === 0) {
    return <MagicBallWrongEndGame/>;
  }

  if (lives === 1) {
    return <MagicBallWrongFallAngel/>;
  }

  return <MagicBallWrongDarkForce/>;
}

export function getMagicBallPredictionText(points: number) {

  if (points < 1000) {
    return predictions.lowResult[Math.floor(Math.random() * predictions.lowResult.length)];
  }

  if (points < 1500) {
    return predictions.mediumResult[Math.floor(Math.random() * predictions.mediumResult.length)];
  }

  return predictions.highResult[Math.floor(Math.random() * predictions.highResult.length)];
}

const predictions = {
  lowResult: [
    "Gatara vidi da u budućnosti češće treba da spavaš sa knjigom pod jastukom",
    "Gatara vidi da u budućnosti treba da staviš grejalicu pod stolicu da se bolje zagreje",
    "Gatara vidi da u budućnosti treba da bolje spremiš puškice za ispite",
    "Gatara vidi da u budućnosti treba da učiniš čitaonicu svojim svetim mestom",
    "Gatara vidi da u budućnosti češće treba da koristiš Google",
    "Gatara predviđa da u budućnosti treba da popiješ više kafe pred ispit",
    "Gatara savetuje da u naredne 3 nedelje večeraš u čitaonici",
    "Gatara misli da je trebalo da preskočiš onu žurku",
    "Gatara predlaže da staviš bosiljak vezan crvenim koncem na radni sto... jer lepo miriše.",
    "zamalo da oboriš Gataru s nogu. Srećom nema noge jer je aplikacija",
    "Gatara kaže ako niko nije video ovaj broj poena, nije se nikad ni desio",
    "Gatara kaže uživaj dok možeš"
  ],
  mediumResult: [
    "Gatara ti poručuje da su sve stvari teške dok ne postanu lagane",
    "Gatara ti poručuje da potapšeš sebe po ramenu",
    "Gatara ti poručuje da kažeš osobi do tebe da te potapše po ramenu",
    "Gatara ti poručuje da možeš slobodno na piće u petak uveče",
    "Gatara vidi da će se tvoj rad na kraju isplatiti",
    "Gatara kaže ne treba da budeš brži od medveda, samo od najsporijeg lika koga medved juri"
  ],
  highResult: [
    "Gatara vidi put u neslućene visine svemira u budućnosti",
    "Gatara vidi da će u budućnosti tvoj algoritam uvek raditi",
    "Gatara zove Google i daje preporuku",
    "Gatara šalje telepatsku preporuku Elonu Masku za ovog neotkrivenog genija",
    "Gatara javlja Tesli u transcendenciju da je otkrila još jednog genija",
    "oborio si Gataru iz kugle i ona je otišla da nazove horoskopski znak tvojim imenom",
    "Gatara vidi da će se ovo zdanje jednoga dana zvati po tebi"
  ]
};
