import React, {Component} from "react";
import IQuestion from "../model/IQuestion";

interface questionResultProps {
  question: IQuestion;
}

export default class QuestionResult extends Component<questionResultProps> {
  render() {
    return (
      <div className={ 'result' }>
        <div className={ 'result__question' }>{ this.props.question.question }</div>
        {
        this.props.question.answers.map(answer => {
          let wrapperClass = "";
          let textClass = 'result--answer-is-normal';
          if (answer.isCorrect && answer.isSelected) {
            wrapperClass = 'result--user-is-correct-wrapper';
            textClass = 'result--user-is-correct';
          } else if (answer.isSelected && !answer.isCorrect) {
            wrapperClass = 'result--user-is-wrong-wrapper';
            textClass = 'result--user-is-wrong';
          }

          if (answer.isCorrect && !answer.isSelected) {
            textClass = 'result--answer-is-correct';
          }
          return (
            <div key={ answer.id } className={ wrapperClass }>
              <div className={ textClass } key={ answer.id }> { answer.answer } </div>
            </div>
          )})
        }
      </div>
    )
  }
}
