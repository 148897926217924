import React, {Component} from "react";
import {RouteComponentProps} from "react-router-dom";
import {InfoBoardText} from "../components/InfoBoardText";
import {Page} from "../components/Page";
import Website from '../assets/images/website.svg';
import Instagram from '../assets/images/instagram.svg';
import Facebook from '../assets/images/facebook.svg';
import LinkedIn from '../assets/images/linkedin.svg';
import ByteoutCompany from '../assets/images/byteout-company@2x.png';
import Logo from '../assets/images/byteout-logo.svg';


export default class Company extends Component<RouteComponentProps> {
  render() {
    let infoBoardTextContent =
      {
        goBack:  this.props.history.goBack ,
        title: "Kompanija",
      };
    return (
      <Page className={"page--with-board"}>
        <Page.Content>
          <InfoBoardText {...infoBoardTextContent} />
          <div className={"company__image"}>
            <img className={"company__logo"} src={Logo} alt={"Byteout"}/>
            <img className={"company__company-image"} src={ByteoutCompany} alt="Byteout company" />
            <ul className={"company__social"}>
              <li>
                <a href="https://www.byteout.com/">
                  <img alt={"Byteout website"} src={Website}/>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/byteout/">
                  <img alt={"Byteout facebook"} src={Facebook}/>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/byteout-software">
                  <img alt={"Byteout Linkedin"} src={LinkedIn}/>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/byteout_software/">
                  <img alt={"Byteout instagram"} src={Instagram}/>
                </a>
              </li>
            </ul>
          </div>

          <div className={"company__text"}>
            <p>Ovo su mesta na kojima možeš bolje da upoznaš Byteout tim i kulturu.</p>

            <p>Uvek smo u potrazi za fenomenalnim novim članovima tima.</p>
          </div>

        </Page.Content>
      </Page>
    );
  }
}
