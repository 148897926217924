import React from "react";
import {NavLink} from "react-router-dom";

export default class NotFound extends React.Component {
  render() {
    return (
      <div className="App">
        <h1>NOT FOUND</h1>
        <NavLink to={"/login"}>BACK TO LOGIN</NavLink>
      </div>
    );
  }
}
