import React, {Component} from "react";
import IQuestion from '../model/IQuestion';
import {Answer} from "./Answer";

interface QuestionProps {
  question: IQuestion;
  sendUserAnswer: (answerId: number) => void;
  points: number;
  userAnswered: number
}

export class Question extends Component<QuestionProps> {

  handleAnswerSelect(answerId: string): void {
    this.props.sendUserAnswer(+answerId);
  }

  render() {
    const { question, userAnswered } = this.props;

    return (
      <div className={'question'}>
        <h1 className={'question__text'}>{question.question }</h1>
        <form>
          {
            question.answers &&
            question.answers.map(answer => {

              if (userAnswered && answer.id === userAnswered) {
                answer.isSelected = true;
              }

              return (
                <Answer
                  key={answer.id}
                  answer={answer}
                  handleAnswerSelect={this.handleAnswerSelect.bind(this)}
                  isUserSelected={!!userAnswered}
                />
              )
            })
          }
          </form>
      </div>
    );
  };
}
