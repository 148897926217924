import React, {Component} from "react";
import {InfoBoard} from './InfoBoard';
import { ReactComponent as BackArrow } from '../assets/images/leftArrow.svg';

interface InfoBoardTextProps {
    title: string;
    subtitle?: string;
    goBack: () => void;
}

export class InfoBoardText extends Component<InfoBoardTextProps> {
  render() {
    let score = null;

    if (this.props.subtitle)
      score =
        <div className={'board__break-line-wrapper'}>
          <div className={'board__subtitle'}> {this.props.subtitle} <span> poena</span></div>
        </div> ;

    return (
      <InfoBoard>
        <section className={'board__content-text'}>
            <div className={this.props.subtitle ? 'board__center-content-wrapper' : 'board__title-wrapper'}>
              <div className={'board__title'}>
                {this.props.title}
              </div>
              {score}
            </div>
            <div>
              <button className={'board__return-btn'} onClick={() => this.props.goBack()}>
                <BackArrow />
              </button>
            </div>
        </section>
      </InfoBoard>
    )
  };
}
