import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import Quiz from "./pages/Quiz";
import Result from "./pages/Result";
import Leaderboard from "./pages/Leaderboard";
import NotFound from "./pages/NotFound";
import Company from "./pages/Company";
import Onboarding from "./pages/Onboarding";
import QuizEnd from "./pages/QuizEnd";

export const routes = {
  login: {
    path: '/login',
    exact: true,
    component: Login
  },
  onboarding: {
    path: '/onboarding',
    exact: true,
    component: Onboarding
  },
  quiz: {
    path: '/quiz',
    exact: true,
    component: Quiz
  },
  quizEnd: {
    path: '/quiz-end',
    exact: true,
    component: QuizEnd
  },
  result: {
    path: '/result',
    exact: true,
    component: Result
  },
  leaderboard: {
    path: '/leaderboard',
    exact: true,
    component: Leaderboard
  },
  company: {
    path: '/company',
    exact: true,
    component: Company
  },
};

const Router = () => {
  return (
    <HashRouter >
      <Switch>
        <Route path={routes.login.path} exact={routes.login.exact} component={routes.login.component} />
        <Route path={routes.onboarding.path} exact={routes.onboarding.exact} component={routes.onboarding.component} />
        <Route path={routes.quiz.path} exact={routes.quiz.exact} component={routes.quiz.component} />
        <Route path={routes.quizEnd.path} exact={routes.quizEnd.exact} component={routes.quizEnd.component} />
        <Route path={routes.result.path} exact={routes.result.exact} component={routes.result.component} />
        <Route path={routes.leaderboard.path} exact={routes.leaderboard.exact} component={routes.leaderboard.component} />
        <Route path={routes.company.path} exact={routes.company.exact} component={routes.company.component} />
        <Route exact={true} path="/" component={routes.login.component} />
        <Route path="*" component={NotFound} />
      </Switch>
    </HashRouter>
  );
};

export default Router;
